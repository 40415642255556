@font-face {
  font-family: "JetBrainsMono";
  src: url(./fonts/JetBrainsMono-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Icons";
  src: url(./fonts/icomoon.ttf) format("truetype");
}

body {
  font-family: JetBrainsMono !important;
  /* Full screen width and height */
  width: 100%;
  min-height: 100vh;

  /* Centers the container in the middle of the screen */
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0;
  /* background-color: rgb(220, 220, 220); */
  background-color: #222222;
  color: #f7f7f7 !important;
}

#container {
  /* This will come into play later */
  perspective: 40px;
  box-shadow: 0px 0px 300px 50px rgba(0, 0, 0, 0.75);
  border-radius: 3rem;
}

#inner {
  width: 50rem;
  height: 30rem;
  /* background-color: white; */
  background-color: #424242;

  /* box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.2); */

  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -o-transition: transform 0.5s;

  border-radius: 2rem;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#header {
  display: flex;
  /* margin-top: -12rem; */
  justify-content: space-between;
}

h1,
h2 {
  margin: 0;
  text-align: center;
}

h1 {
  font-size: 3rem;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
}

h2 {
  letter-spacing: 0.5rem;
  text-indent: 2rem;
}

img#profile {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  margin-top: -5rem;
  opacity: 0;
}

.img-onLoad {
  opacity: 1 !important;
  transition: opacity 2s linear;

  border: 0.3rem solid #313131;
  box-shadow: 0px 0px 69px 0px rgba(0, 0, 0, 0.75);
}

#social {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
}

#social > div {
  flex-grow: 1;
  width: 15%;
  text-align: center;
  margin: 1rem;
}

#social > div > a {
  text-decoration: none;
  color: black;
  font-size: 3rem;
}

#social a {
  margin: 1rem;
}

#social a span,
svg {
  color: #f7f7f7;
}

#social {
  font-family: Icons;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn ease-in both;
  /* animation-duration: 7s; */
}

#portfolio a {
  text-decoration: none !important;
  padding: 1rem 2.5rem;

  background-color: #f7f7f7;
  color: #222222;
  font-size: 1.5rem;

  border-radius: 500px;
}

@media only screen and (max-width: 900px) {
  html {
    font-size: 13px;
  }
}

/* @media only screen and (max-width: 700px) {
  html {
    font-size: 11px;
  }
} */

@media only screen and (max-width: 700px) {
  html {
    font-size: 11px;
  }

  #header {
    flex-direction: column;
    align-items: center;
  }

  #inner {
    width: 35rem;
    height: 43rem;
  }

  img#profile {
    margin-top: -2rem;
  }
}

@media only screen and (max-width: 450px) {
  #inner {
    width: 25rem;
    height: 48rem;
  }

  h1,
  h2 {
    text-align: center;
  }

  h2 {
    letter-spacing: 1.5rem;
  }

  img#profile {
    margin-top: 0rem;
  }
}
